/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_LINK,GET_LINKS} from '../Queries/index';
import {SERVER_URL} from '../../../Config/index';
const moment             = require("moment");

class Link {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace
     * @param {*} addLink funkce z apollo, pomocí které se posílají data na server
     */

    addLink = async (addEditLink,callback = null) => {

        const data = this.parent.state.formData;
                      
        let langs = [];
        for(const lang of data.langs){

            langs.push({
                name:                           lang.name,
                ownUrl:                         lang.ownUrl,
                description:                    lang.description,
                membershipPopUpTextUp:          lang.membershipPopUpTextUp,
			    membershipPopUpTextDown:        lang.membershipPopUpTextDown,
                membershipAfterBuyEmailText:    lang.membershipAfterBuyEmailText,
                metaTitle:                      lang.metaTitle,
                metaKeywords:                   lang.metaKeywords,
                metaDescription:                lang.metaDescription,
                lang:                           lang.lang,
                upSellBombTitle:                lang.upSellBombTitle
            });
        }

        var sMem = [...data.selectedMemberships];
        if(sMem.length > 0)
        {
            for(let i in sMem)
            {
                sMem[i] = {...sMem[i],
                    percentageDiscount: sMem[i].percentageDiscount ? parseFloat(sMem[i].percentageDiscount) : 0
                }
            }
        }

        var membershipPercentageDiscountDateFrom = null;
        if(data.membershipPercentageDiscountDateFrom && data.membershipPercentageDiscountDateFrom != "")
            membershipPercentageDiscountDateFrom = moment(data.membershipPercentageDiscountDateFrom).format("YYYY-MM-DD HH:mm:ss");
        
        var membershipPercentageDiscountDateTo = null;
        if(data.membershipPercentageDiscountDateTo && data.membershipPercentageDiscountDateTo != "")
            membershipPercentageDiscountDateTo = moment(data.membershipPercentageDiscountDateTo).format("YYYY-MM-DD HH:mm:ss");
            
        addEditLink({
            variables:{
                linkID:data.linkID,
                parentID:data.parentID,
                themeID:data.themeID,
                active:parseInt(data.active),
                inHeader:parseInt(data.inHeader),
                inFooter:parseInt(data.inFooter),
                isBlog:parseInt(data.isBlog),
                hideMenu:parseInt(data.hideMenu),
                thanksLinkID:data.thanksLinkID,
                membershipPercentageDiscountDateFrom,
                membershipPercentageDiscountDateTo,
                metaImage:data.metaImage,
                langs,
                memberships:sMem,
                upSellBombItems: data.selectedUpSellBomb,
                generateTranslations:data.generateTranslations
            }
        }).then(responseData => {
            if(callback){
                callback(true,responseData);
            }
        }, errorData =>{
            if(callback){
                callback(false,errorData);
            }
        });
                  
               
    } 

    
    /**
     * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
     */

    async getData(){

        var linkID = 0;
        if(this.parent.props.selectedLinkID)linkID = this.parent.props.selectedLinkID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_LINK,
            errorPolicy:"all",
            variables:{linkID:linkID}, 
            fetchPolicy: 'network-only'
        });
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.linkWithLangs)
            {    
                let langs = this.completeLangsArray(data.data.linkWithLangs.langs,data.data.allLanguageMutations);    
                
                var metaImage = null;
                
                if(data.data.linkWithLangs.metaImage)
                    metaImage = SERVER_URL + "/Public/Images/Link/fb_" + data.data.linkWithLangs.metaImage;
                else 
                    metaImage = null;   
                

                var selectedMemberships = [];
                if(data.data.linkWithLangs.memberships)
                {
                    for(let val of data.data.linkWithLangs.memberships)
                    {
                        selectedMemberships.push({
                            membershipDurationID:        val.membershipDurationID,
                            percentageDiscount:          val.percentageDiscount
                            
                        });
                    }
                }
                
                var selectedUpSellBomb = [];
                if(data.data.linkWithLangs.upSellBombItems)
                {
                    for(let val of data.data.linkWithLangs.upSellBombItems)
                    {
                        selectedUpSellBomb.push({
                            upSellBombTypeID: val.upSellBombTypeID,
                            valueID:          val.valueID
                            
                        });
                    }
                }

                this.parent.setState({
                    apolloError:"",
                    selectedLinkID:linkID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allMemberships: data.data.allMemberships,
                    allServices: data.data.allServices,
                    allLinks:data.data.allLinks,
                    metaImage:metaImage,
                    formData:{...this.parent.state.formData,
                        linkID:                                 data.data.linkWithLangs.linkID,
                        parentID:                               data.data.linkWithLangs.parentID,
                        themeID:                                data.data.linkWithLangs.themeID,
                        active:                                 data.data.linkWithLangs.active,
                        inHeader:                               data.data.linkWithLangs.inHeader,
                        inFooter:                               data.data.linkWithLangs.inFooter,
                        isBlog:                                 data.data.linkWithLangs.isBlog,
                        hideMenu:                               data.data.linkWithLangs.hideMenu,
                        thanksLinkID:                           data.data.linkWithLangs.thanksLinkID,
                        membershipPercentageDiscountDateFrom:   data.data.linkWithLangs.membershipPercentageDiscountDateFrom ? moment(data.data.linkWithLangs.membershipPercentageDiscountDateFrom).toDate() : "",
                        membershipPercentageDiscountDateTo:     data.data.linkWithLangs.membershipPercentageDiscountDateTo ? moment(data.data.linkWithLangs.membershipPercentageDiscountDateTo).toDate() : "",
                        langs:                                  langs,
                        selectedMemberships:                    selectedMemberships,
                        selectedUpSellBomb:                     selectedUpSellBomb
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(linkID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);     
            this.parent.setState({
                selectedLinkID: linkID,
                allLanguageMutations: data.data.allLanguageMutations,
                allMemberships: data.data.allMemberships,
                allServices: data.data.allServices,
                allLinks:data.data.allLinks,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = [...langsFromDb];
        for(const l of allActiveLanguages){
            let langSet = false;
            for(let i = 0; i < langs.length;i++){
                if(l.suffix == langs[i].lang){
                    langs[i] = {...langs[i],metaImage : null};
                    langSet = true;
                    break;
                }
            }
            if(!langSet){
                langs.push({
                    name:"",                    
                    description:"",
                    membershipPopUpTextUp:"",
			        membershipPopUpTextDown:"",
                    membershipAfterBuyEmailText:"",
                    metaTitle:"",
                    metaKeywords:"",
                    metaDescription:"",
                    metaImage:null,
                    ownUrl:"",
                    lang:l.suffix,
                    upSellBombTitle:""
                });
            }
        }
        return langs;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allLinks } = cache.readQuery({ query: GET_LINKS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditLink,...allLinks]   ;
            
            cache.writeQuery({ 
                query:GET_LINKS,
                variables:variables,
                data:{
                    allLinks: arr
                } 
            });

        }else{

            var arr = [...allLinks];
            
            arr.forEach((item,index) => {
                
                if(item.linkID == response.data.addEditLink.linkID){
                    arr[index].linkID       = response.data.addEditLink.linkID;
                    arr[index].title           = response.data.addEditLink.title;
                    arr[index].lang            = response.data.addEditLink.lang;

                    if(response.data.addEditLink.photo){
                        arr[index].photo = response.data.addEditLink.photo;
                    }
                }

            });
            cache.writeQuery({ 
                query:GET_LINKS,
                variables:variables,
                data:{
                    allLinks: arr
                } 
            });
        }
    }


    /**
     * Otevře obrázke, zpracuje a zobrazí jeho náhled
     * @param {*} e 
     */

    openImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

    openMetaImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                metaImage:dataURL,
                formData:{...this.parent.state.formData,metaImage:photo}
            })

        };
        reader.readAsDataURL(photo);
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteLinks.split(",");

        const { allLinks } = cache.readQuery({ query: GET_LINKS ,variables});
        var arr = [...allLinks];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.linkID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_LINKS,
            variables,
            data:{
                allLinks: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

    SetFormData(type,value,CallBack){

        this.parent.setState({formData:{...this.parent.state.formData,[type]:value}},() => {
            if(CallBack)
                CallBack()
        });
    }

    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    /**
     * Vraci počet znaků zadané proměnné v objektu jazykových mutací lang
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns počet znaků
     */
    getCharCount = (paramName) => {        
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[paramName].length;
            }
        }
        return 0;
    }

    

    /**
     * Nastaví datum publikování
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    setPublishDate(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang.publishDate = date;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Zjisti, jestli byl již daný článek publikován
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    linkPublished(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang.published){
                    return true;
                }else{
                    return false;
                }
            }
        }
        return false;
    }

    SelectMembership(membershipDurationID)
    {
        const selectedMemberships = [...this.parent.state.formData.selectedMemberships];
        
        var check = false;
        for(let i = 0; i < selectedMemberships.length; i++)
        {
            if(selectedMemberships[i].membershipDurationID == membershipDurationID)
            {
                check = true;
                selectedMemberships.splice(i,1);
                break;
            }
        }

        if(!check)
            selectedMemberships.push({
                membershipDurationID:membershipDurationID,
                percentageDiscount:0,
            });

        this.parent.setState({formData:{...this.parent.state.formData,selectedMemberships}});

    }

    SelectUpSellItem(valueID,upSellBombTypeID)
    {
        const selectedUpSellBomb = [...this.parent.state.formData.selectedUpSellBomb];
        
        var check = false;
        for(let i = 0; i < selectedUpSellBomb.length; i++)
        {
            if(selectedUpSellBomb[i].valueID == valueID && selectedUpSellBomb[i].upSellBombTypeID == upSellBombTypeID)
            {
                check = true;
                selectedUpSellBomb.splice(i,1);
                break;
            }
        }

        if(!check)
            selectedUpSellBomb.push({
                valueID,
                upSellBombTypeID
            });

        this.parent.setState({formData:{...this.parent.state.formData,selectedUpSellBomb}});

    }

    SetMembershipDiscountData(value,membershipDurationID,type){

        const selectedMemberships = [...this.parent.state.formData.selectedMemberships];
        var check = false;

        for(let i = 0; i < selectedMemberships.length; i++)
        {
            if(selectedMemberships[i].membershipDurationID == membershipDurationID)
            {
                check = true;
                selectedMemberships[i] = {...selectedMemberships[i],[type]:value};
            }
        }

        if(!check)
            selectedMemberships.push({
                membershipDurationID:membershipDurationID,
                percentageDiscount:(type == "percentageDiscount" ? value : 0)
            });

        this.parent.setState({formData:{...this.parent.state.formData,selectedMemberships}});
    }

        
}

export default Link;