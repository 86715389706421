import React,{ Component } from 'react';


class SelectSubLink extends Component{

    constructor(props){
        super(props);
    }

    render(){ 

        return(
            <React.Fragment>
                {this.props.links && this.props.links.map((item,index) => (
                    <React.Fragment key={index}>
                        <option key={index+1} value={item.linkID} >{item.name}</option>
                        <SelectSubLink links={item.subLink} />  
                    </React.Fragment> 
                    
                ))}
            </React.Fragment>

            
        )

    }

}

export default SelectSubLink; 