/*
    Přidání štítku
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_ARTICLE,GENERATE_ARTICLE_LANG} from '../Queries/article';
import { withApollo } from 'react-apollo'; 
import ArticleLib from '../Library/article';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import RecommendedArticles from './RecommendedArticles';
import ArticleMarketings from './ArticleMarketings';
import ArticleServices from './ArticleServices';
import ArticleForms from './ArticleForms';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import LinkSelector from '../../Content/Components/LinkSelector';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';
import settingsIcon from '../../../Public/Images/settings.svg';
import ArticleTranslateSettings from './ArticleTranslateSettings';
import ProgressBar from '../../../GlobalComponents/ProgressBar';

registerLocale('cs', cs);

const INITIAL_STATE = {
    apolloError:"",
    selectedArticleID:null,
    mainPhoto:"",
    selectedTab:1,
    showPublishNotifi:false,
    showUnPublishNotifi:false,
    addArticle:null,
    addLoading:false,
    showTranslateSettings:false,
    translatedLangsCount:0,
    formData:{
        selectedLang:"cz",
        generateTranslations:{
            enabled:true,
            onlyForParams:[],
            onlyFor:""
        },
        articleID:0,
        sendNewsletter:true,
        publishAllLangs:false,
        alone:false,
        withoutMainImage:false,
        photo:null,
        generateRandomRecommended:true,
        linkIDs:[],
        langs:[],
    },
    allLanguageMutations:[],
    allServices:[],
    allForms:[]
}

class AddEditArticle extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.articleLib = new ArticleLib(this); 
    }

    componentDidMount(){

        this.articleLib.getData();

        if(this.props.selectedArticleID && this.props.selectedArticleID != 0)
            this.articleLib.SetFormData("generateTranslations",{...this.state.formData.generateTranslations,enabled:false});
        
    }
    
    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }

    showUnPublishNotifi = () =>{
        this.setState({
            showUnPublishNotifi:true
        });
    }
    unpublish = (result) => {
        if(result){

            var langs = [this.state.formData.selectedLang];
            if(this.state.formData.publishAllLangs)
            {
                langs = [];
                for(let val of this.state.formData.langs)
                {
                    langs.push(val.lang);
                }
            }

            this.articleLib.unpublishArticle(this.state.selectedArticleID,langs,this.props.listVariables);                   
        }
        this.setState({
            showUnPublishNotifi:false
        });
    }

    showPublishNotifi = (addArticle) =>{
        this.setState({
            addArticle,
            showPublishNotifi:true
        });
    }
    saveAndPublish = (result) => {
        if(result){

            this.setState({publishing:true});
            this.articleLib.addArticle(this.state.addArticle, async (status,responseData) => {
                
                if(status)
                {  
                    var langs = [this.state.formData.selectedLang];
                    if(this.state.formData.publishAllLangs)
                    {
                        langs = [];
                        for(let val of this.state.formData.langs)
                        {
                            if(!val.published)
                                langs.push(val.lang);
                        }
                    }
    
                    this.articleLib.publishArticle(this.state.selectedArticleID,langs,this.props.listVariables);                   

                }
            })
            
        }
        this.setState({
            showPublishNotifi:false
        });
    }

    setSelectedLinks = (linkIDs) => {  
        this.setState({formData:{...this.state.formData,linkIDs}});
    }
    
    setGenerateRandomRecommendedValue = (generateRandomRecommended) => {        
        this.setState({formData:{...this.state.formData,generateRandomRecommended}});
    }

    render(){

        const {
            apolloError,
            mainPhoto,
            allLanguageMutations,
            allServices,
            allForms,
            selectedTab,
            selectedArticleID,
            showPublishNotifi,
            showUnPublishNotifi,
            addLoading,
            showTranslateSettings
        } = this.state;
        var {listVariables} = this.props;


        return(

            <Mutation 
                mutation={ADD_EDIT_ARTICLE}
                update = {async (cache, response) => {

                    var success = true;

                    if(this.state.formData.generateTranslations.enabled)
                    {
                        this.setState({translatedLangsCount:3,addLoading:true});
                        for(let val of allLanguageMutations)
                        {
                            if(val.suffix != "cz" && val.suffix != "en" && val.suffix != "us")
                            {
                                try
                                {
                                    await this.props.client.mutate({
                                        mutation:GENERATE_ARTICLE_LANG,
                                        variables:{
                                            lang: val.suffix,
                                            articleID: response.data.addEditArticle.articleID,
                                            isEdit: (this.props.selectedArticleID && this.props.selectedArticleID != 0 ? true : false),
                                            onlyForParams:this.state.formData.generateTranslations.onlyForParams
                                        }
                                    });

                                    this.setState({translatedLangsCount:this.state.translatedLangsCount + 1});

                                }
                                catch(err)
                                {
                                    const helper = new Helper(); 
                                    this.setState({
                                        err:helper.getApolloErrorText(err)
                                    });
                                    success = false;
                                    break;
                                }
                            }
                        }

                        this.setState({addLoading:false});
                    }

                    if(success)
                    {

                        this.articleLib.updateList(cache,response,selectedArticleID,listVariables);
                        
                        let notify = new Notification();

                        if(selectedArticleID && selectedArticleID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                        else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                        if(selectedArticleID && selectedArticleID != 0){
                            this.props.openCloseModal(false);
                        }else{
                            
                            this.setState({
                                selectedArticleID:response.data.addEditArticle.articleID,
                                formData:{...this.state.formData, 
                                    articleID:response.data.addEditArticle.articleID
                                }
                            });
                        
                        }
                    }
                }}
 
            >
            {
                (addArticle,{data,loading,error}) => {

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    const {
                        selectedLang,
                        generateRandomRecommended,
                        linkIDs,
                        sendNewsletter,
                        publishAllLangs,
                        alone,
                        withoutMainImage,
                        generateTranslations
                    } = this.state.formData;
                    
                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => !loading && !addLoading && this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h4 className="modal-title">{(selectedArticleID && selectedArticleID != 0 ? "Úprava článku" : "Přidání článku")}</h4>

                                    {!loading && !addLoading ? 
                                        <>    
                                            <div className="select-lang">

                                                <div className={"form-check generate-all-langs" + (!generateTranslations.enabled ? " bigger" : "")}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="generateTranslations" 
                                                        id={"generateTranslations"} 
                                                        checked={generateTranslations.enabled} 
                                                        onChange={(e) => {
                                                            this.articleLib.SetFormData("generateTranslations",{...generateTranslations,enabled:e.target.checked},() => {
                                                                this.articleLib.SetFormData("selectedLang","cz");
                                                            });
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                        Vygenerovat všechny překlady
                                                    </label>
                                                    
                                                </div>

                                                {generateTranslations.enabled && selectedArticleID && selectedArticleID != 0 ?
                                                    <img 
                                                        className="cursor-pointer"
                                                        style={{height:"25px",marginLeft:"10px"}} 
                                                        src={settingsIcon} 
                                                        onClick={() => this.setState({showTranslateSettings:!showTranslateSettings})}
                                                    />
                                                :null}
                                                
                                                {!generateTranslations.enabled ?
                                                    <select className="form-control" name="selectedLang" onChange={(e) => this.articleLib.formHandle(e)}>
                                                        {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.suffix}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                :null}

                                            </div>

                                            <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                                <span aria-hidden="true">x</span>
                                                <span className="sr-only">Close</span>
                                            </button>

                                        </>
                                    :null}

                                    {showTranslateSettings ?
                                        <ArticleTranslateSettings 
                                            generateTranslations = {generateTranslations}
                                            parent = {this}
                                        />
                                    :null}
                                </div>
                                <div className="modal-body">

                                    {error || apolloError ?
                                        <Error text={error || apolloError} />
                                    :
                                    <div>
                                        
                                        {!loading && !addLoading ?
                                            <Fragment>  
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 2 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#recomended" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,2)}}>Doporučené články</a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 3 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#marketing" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,3)}}>Marketing</a>
                                                    </li>

                                                    {true ?
                                                    <>
                                                        <li className="nav-item">
                                                            <a className={(selectedTab == 4 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#service" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,4)}}>Služby</a>
                                                        </li>   
                                                        <li className="nav-item">
                                                            <a className={(selectedTab == 5 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#form" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,5)}}>Formuláře</a>
                                                        </li> 
                                                    </>
                                                    :null}
                                                </ul>   

                                                
                                                {selectedTab == 1 &&   
                                                    <div>                                                       
                                                        <div className="form-group input-group">
                                                            <div className="custom-file">
                                                                
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    name="file"
                                                                    required
                                                                    multiple
                                                                    onChange={(e) => this.articleLib.openImage(e)}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                            </div>
                                                        </div>
                                                        
                                                        {mainPhoto &&
                                                            <div className="row">
                                                                <div className="col-12 col-sm-4 mb-4">
                                                                    <img className = "maxwidth-100" src={mainPhoto} />
                                                                </div>
                                                                
                                                                <div className="col-12 col-sm-8">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="form-check form-check-inline form-group">
                                                                                <div>
                                                                                    <input className="form-check-input" type="checkbox" id="alone" name="alone" checked = {alone} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="alone">Osamocený článek</label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" id="withoutMainImage" name="withoutMainImage" checked = {withoutMainImage} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="withoutMainImage">Bez hlavního obrázku</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">Nadpis článku  ({selectedLang})</label>
                                                                                        <div className="form-group">
                                                                                            <input className="form-control" type="text" name="title" value={this.articleLib.getLangValue("title")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>  
                                                                            </div>  
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">Datum zveřejnění  ({selectedLang})</label>
                                                                                        <div className="form-group">
                                                                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={this.articleLib.getLangValue("publishDate")} onChange={date => this.articleLib.setPublishDate(date)} />
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>
                                                                                {selectedArticleID != 0 &&
                                                                                    <div className="col-6"> 
                                                                                        <div className="form-group">
                                                                                            <label className="input-label">&nbsp;</label>
                                                                                            <div className="form-group">
                                                                                                {!this.articleLib.articlePublished() || (publishAllLangs && this.articleLib.IsSomeArticleNotPublished()) ?
                                                                                                    <button type="button" className="btn btn-primary " onClick={() => this.showPublishNotifi(addArticle)}>Uložit a publikovat</button>
                                                                                                :
                                                                                                    <>
                                                                                                        <div className="bold fs12 green">Článek již byl publikován</div>
                                                                                                        <button type="button" className="btn btn-warning" style={{padding:'4px'}} onClick={() => this.showUnPublishNotifi()}>Odpublikovat</button>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>  
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {selectedArticleID != 0 &&
                                                                                <>
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox" id="publishAllLangs" name="publishAllLangs" checked = {publishAllLangs} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                        <label className="form-check-label" htmlFor="publishAllLangs">
                                                                                            {this.articleLib.IsSomeArticleNotPublished() ?
                                                                                                "Publikovat všechny jazyky (pouze nepublikované)"
                                                                                            :
                                                                                                "Odpublikovat všechny jazyky"
                                                                                            }
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox" id="toNewsletter" name="sendNewsletter" checked = {sendNewsletter} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                        <label className="form-check-label" htmlFor="toNewsletter">Při publikování zaslat newsletter</label>
                                                                                    </div>
                                                                                </>
                                                                            }

                                                                           
                                                                           
                                                                            
                                                                        </div> 
                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">Přiřadit odkazu</label>
                                                                            <LinkSelector allArticleLinks={linkIDs}  setSelectedLinks={this.setSelectedLinks}/>                                                                            
                                                                        </div>   
                                                                    </div>                                                   
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Perex článku ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <TinyMCEBasic 
                                                                                name="perex"
                                                                                value={this.articleLib.getLangValue("perex")}
                                                                                OnEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"perex")}
                                                                            />
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="input-label">Text článku ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <TinyMCEEditor allServices = {allServices} allForms = {allForms} onChange={(content,type) => this.articleLib.formLangHandleEditor(content,type)} initialValue={this.articleLib.getLangValue("text")} />           
                                                                        </div>
                                                                    </div>
                                                                    {false ?
                                                                        <div className="form-group">
                                                                            <label className="input-label">Marketingový text ({selectedLang})</label>
                                                                            <div className="form-group">
                                                                                <TinyMCEBasic 
                                                                                    name="marketingText"
                                                                                    value={this.articleLib.getLangValue("marketingText")}
                                                                                    OnEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"marketingText")}
                                                                                />
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    :null}
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="card main">
                                                                        <div className="card-header d-flex align-items-center">
                                                                            SEO
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-6">
                                                                                    <label className="input-label">Meta nadpis  ({selectedLang})</label>
                                                                                    <div className="form-group">
                                                                                        <input className="form-control" type="text" name="metaTitle" value={this.articleLib.getLangValue("metaTitle")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    </div>
                                                                                </div>  
                                                                                <div className="col-12 col-sm-6">
                                                                                    <label className="input-label">Meta klíčová slova  ({selectedLang})</label>
                                                                                    <div className="form-group">
                                                                                        <input className="form-control" type="text" name="metaKeywords" value={this.articleLib.getLangValue("metaKeywords")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    </div>
                                                                                </div> 
                                                                                <div className="col-12">
                                                                                    <label className="input-label">Meta popis ({selectedLang})</label>
                                                                                    <div className="input-group">
                                                                                        <input className="form-control" type="text" name="metaDescription" value={this.articleLib.getLangValue("metaDescription")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                        
                                                                                        <div className="input-group-append">
                                                                                            <span className={"input-group-text " + (this.articleLib.getCharCount("metaDescription") > 160 ? 'red' : '')}>{this.articleLib.getCharCount("metaDescription")}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }                                                    
                                                    </div>
                                                }
                                                {selectedTab == 2 &&  
                                                    <RecommendedArticles setGenerateRandomRecommendedValue={this.setGenerateRandomRecommendedValue} generateRandomRecommended={generateRandomRecommended} articleID={selectedArticleID} />
                                                }
                                                {selectedTab == 3 &&  
                                                    <ArticleMarketings articleID={selectedArticleID} />
                                                }
                                                {selectedTab == 4 &&  
                                                    <ArticleServices articleID={selectedArticleID} />
                                                }
                                                {selectedTab == 5 &&  
                                                    <ArticleForms articleID={selectedArticleID} />
                                                }
  
                                            </Fragment>

                                        :
                                            <div>
                                                {generateTranslations.enabled ?
                                                    <div>
                                                        <p className = "text-center">Překládám...</p>
                                                        <ProgressBar
                                                            progressValue = {this.state.translatedLangsCount}
                                                            totalValue = {allLanguageMutations.length}
                                                        />
                                                    </div>
                                                :
                                                    <Loading />  
                                                }
                                            </div> 
                                        }  

                                    </div>
                                    }
               
                                </div>
                                <div className="modal-footer">   
                                    {!loading && !addLoading ?    
                                        <>                          
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.articleLib.addArticle(addArticle)}>{(selectedArticleID && selectedArticleID != 0 ? "Uložit" : "Přidat")}</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                        </>
                                    :null}
                                </div>

                                {showPublishNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete publikovat zvolený článek ?'} callback={this.saveAndPublish} /> }	
                                {showUnPublishNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odpublikovat zvolený článek ?'} callback={this.unpublish} /> }
                                
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditArticle);