/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

export const ADD_EDIT_ORDER = gql`
    mutation AddEditOrder($orderID:ID,$manufacturedStatus:String){
        addEditOrder(orderID:$orderID,manufacturedStatus:$manufacturedStatus){
            customerPaymentID
            manufacturedStatus
            dateManufacturedStatusChanged
        }
    }
`;

export const GET_ORDERS = gql`
    query AllOrders($offset:Int, $limit: Int,$status:String,$dateFrom:String,$dateTo:String){
        allOrders(offset:$offset,limit:$limit,status:$status,dateFrom:$dateFrom,dateTo:$dateTo){
            customerPaymentID
            customerID
            status
            manufacturedStatus
            amount
            dateAdd
            dateManufacturedStatusChanged
            currencyCode
            lang
            customer{
                name
                surname
                email
                street
                city
                zip
                countryID
                domainID
                domain{
                    currencyCode
                    lang
                }
                country{
                    name
                }
            }
            
        }
        allOrdersCount(status:$status,dateFrom:$dateFrom,dateTo:$dateTo)
        
    }
`;

export const GET_ORDER = gql`
    query Order($orderID:ID){
        order(orderID:$orderID){
            customerPaymentID
            manufacturedStatus           
        } 
        allManufacturedStatus{
            status
            label
        }
    }
`;