import React,{ Component, Fragment,useState } from 'react';
import {GET_CAMPAIGN_CONTACTS,UNSUBSCRIBE_CAMPAIGN_CONTACTS} from '../Queries/campaignContact.js';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import { withApollo, useQuery,useMutation } from 'react-apollo';
import Helper from '../../../Library/helper';
import CampaignEmail from '../Library/campaignEmail.js';
import moment from 'moment';
import {UpdateAfterDelete} from '../Library/campaignContact';
import AddCampaignContact from './AddCampaignContact';
import SearchInput from '../../../GlobalComponents/SearchInput';


const CampaignContacts = (props) => {

    const {campaignID} = props;

    const [listVariables,SetListVariables] = useState({
        campaignID: campaignID, 
        searchText:"",
        limit:10000000,
        offset:0,
        lang:""
    })

    
    const [showUnsubscribeNotifi,SetShowUnsubscribeNotifi] = useState(false);
    const [showAddEmails, SetShowAddEmails] = useState(false);
    const [selectedCampaignContacts,SetSelectedCampaignContacts] = useState([]);

    const {data,loading, error} = useQuery(GET_CAMPAIGN_CONTACTS,{
        variables:listVariables,
        fetchPolicy:"network-only"
    });

    const [Unsubscribe,{loading:unsLoading, error:unsError}] = useMutation(UNSUBSCRIBE_CAMPAIGN_CONTACTS,{
        update:(cache, response) => {
            UpdateAfterDelete(cache, response,listVariables)
        }
    });
    
    const UnsubscribeContact = (e,campaignContactID) => {

        var checked = e.target.checked;
        var arr = [...selectedCampaignContacts];

        if(!checked){
            for(let i in selectedCampaignContacts){
                if(selectedCampaignContacts[i] == campaignContactID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [campaignContactID,...arr];
        }

        
        SetSelectedCampaignContacts(arr);
  
    }

    const DelItems = (action) => {

        if(action){

            let campaignContactIDs = selectedCampaignContacts.join(",");

            Unsubscribe({
                variables:{
                    campaignContactIDs: campaignContactIDs
                }
            })
        }

        SetShowUnsubscribeNotifi(false);
        SetSelectedCampaignContacts([]);
    }

    const ShowDelNotifi = () => {

        if(selectedCampaignContacts.length > 0){
            SetShowUnsubscribeNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste kontakt.',false,true,props.client);
        }
    }
    
    return(

        <Fragment>

            {loading ?
                <Loading />
            :
                <Fragment>

                    <div className="d-flex align-items-center">

                        <div style={{width:"210px"}}>
                            <select value={listVariables.lang} className="form-control" name="selectedLang" onChange={(e) => SetListVariables({...listVariables,lang:e.target.value})}>
                                <option key={0} value={""}> všechny jazyky </option>
                                {data.allLanguageMutations && data.allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>

                        <SearchInput value={listVariables.searchText} className="form-control ml-2" placeholder="Vyhledat email" onChange={(text) => SetListVariables({...listVariables,offset:0,searchText:text})} />
                        
                        <button className="btn btn-success btn-thiner ml-2" onClick={() => SetShowAddEmails(true)}>Přidat emaily</button>
                        <button className="btn btn-danger btn-thiner ml-2" onClick={() => ShowDelNotifi()}>Označené odhlásit z kampaně</button>
                        
                    </div>

                    <div className="data-list mt-2">

                        <div className="data-list-item header">
                            <div className="data-list-item-content">
                                <div className="static text-center x-small">Jazyk</div>
                                <div>Email</div>
                                <div className="static text-center">Přidáno</div>
                                <div className="static text-center">Další zaslání</div>
                                <div className="static text-center">Dokončeno</div>
                                <div className="static text-center">Odhlášeno</div>
                                <div className="static text-center">Odhl. adminem</div>
                                <div className="static text-right">Možnosti</div>
                            </div>
                        </div>

                        {data.allCampaignContacts && data.allCampaignContacts.map((item,index) => {

                            var checked = false;
                            for(let i in selectedCampaignContacts){
                                if(selectedCampaignContacts[i] == item.campaignContactID)checked = true;
                            }

                            return(
                                
                                <div key={index} className="data-list-item">
                                    <div className="data-list-item-content">
                                       
                                        <div className="text-center static x-small">{item.lang}</div>
                                        <div>{item.email}</div>
                                        
                                        <div className="text-center static">{moment(item.dateAdd).format("DD.MM.YYYY")} <br /> {moment(item.dateAdd).format("HH:mm:ss")}</div>
                                        <div className="text-center static">
                                            {item.sendDate && item.sendDate != "0000-00-00 00:00:00" ? 
                                            <>
                                                {moment(item.sendDate).format("DD.MM.YYYY")}
                                                <br /> 
                                                {moment(item.sendDate).format("HH:mm:ss")}
                                            </>
                                            :
                                                "------"
                                            }
                                        </div>
                                        <div className="text-center static">{item.isFinished == 1 ? "ANO" : "NE"}</div>
                                        <div className="text-center static" dangerouslySetInnerHTML={{__html:(item.dateUnsubscribe != "0000-00-00 00:00:00" ? moment(item.dateUnsubscribe).format("DD.MM.YYYY") + " <br /> " + moment(item.dateUnsubscribe).format("HH:mm:ss") : "------")}}></div>
                                        <div className="text-center static">{item.unsubscribeByAdminID == 0 ? "NE" : "ANO"}</div>
                                        <div className="text-right static">
                                            <input className="delete-checked" type="checkbox" name="deleteCampaignEmail[]" checked={checked} onChange = {(e) => UnsubscribeContact(e,item.campaignContactID)} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}  
                                        

                        {data.allCampaignContacts && data.allCampaignContacts.length == 0 &&
                            <NoItems text={"Momentálně se zde nenachází žádné kontakty."} />
                        }       

                    </div>

                    {showUnsubscribeNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odhlásit vybrané emaily z kampaně ?'} callback={DelItems} /> }
                    {showAddEmails &&
                        <AddCampaignContact allLanguageMutations = {data.allLanguageMutations} SetShowAddEmails={SetShowAddEmails} campaignID = {campaignID} listVariables={listVariables} />
                    }
                </Fragment>
            }
        </Fragment>
                        

    );

    
}

export default withApollo(CampaignContacts);
