import React,{ Component, Fragment } from 'react';
import {Query,Mutation} from 'react-apollo';
import {GET_ARTICLE_SERVICES,DELETE_ARTICLE_SERVICE,ADD_ARTICLE_SERVICE,UPDATE_ARTICLE_SERVICE_PRIORITY} from '../Queries/article.js';
import {SEACRH_SERVICES} from '../../Service/Queries/index';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import SearchInput from '../../../GlobalComponents/SearchInput';
import { withApollo } from 'react-apollo';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Helper from '../../../Library/helper';
import Article from '../Library/article.js';

const INITIAL_STATE = {
    showDeleteNotifi:false,
    deleteArticleService:"",
    articleServiceID:0,
    selectedArticleServices:[],
    showSearchedServices:false,
    searchedServices:[]
}

class ArticleServices extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

    }

    componentDidMount(){
    }

    /**
     * smazání doporučených článků
     * @param {*} deleteArticleService funkce, která posílá data na server
     * @param {*} articleServiceID ID doporučeného článku
     */
    
    deleteItems = (e,deleteArticleService,articleServiceID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedArticleServices];

        if(!checked){
            for(let i in this.state.selectedArticleServices){
                if(this.state.selectedArticleServices[i] == articleServiceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleServiceID,...arr];
        }

        
        this.setState({
            deleteArticleService,
            selectedArticleServices:arr
        });
  
    }

    delItems = (action) => {

        if(action){

            let articleServiceIDs = this.state.selectedArticleServices.join(",");

            this.state.deleteArticleService({
                variables:{
                    articleServiceIDs: articleServiceIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi = () => {

        if(this.state.selectedArticleServices.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste službu.',false,true,this.props.client);
        }
    }

    searchService = async (value) =>{        

        var data = await this.props.client.query({ 
            query: SEACRH_SERVICES,
            variables:{
                text:value,
                lang:"cz"
            }, 
            fetchPolicy: 'network-only'
        });

        this.setState({
            showSearchedServices:true,
            searchedServices:data.data.searchServices
        })
    
    }
    
    onDragEnd = (result,updateArticleServicePriority) => {

        const IDs = result.draggableId.split("-");

        updateArticleServicePriority({
            variables:{
                articleServiceID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        const {articleID} = this.props;
        const {selectedArticleServices,showSearchedServices,searchedServices} = this.state;

        return(
            <div>
                
                <Query query={GET_ARTICLE_SERVICES} variables={{articleID,lang:"cz"}} fetchPolicy = 'network-only' >
                {({data,loading,error,refetch}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_ARTICLE_SERVICE}
                            
                            update = {(cache, response) => {

                                let ar = new Article();
                                ar.updateAfterDeleteArticleService(cache, response,articleID);

                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);

                            }}
                        >   
                        {(deleteArticleService) => {    

                            return(

                                <Fragment>

                                    <div className="row form-group mb-0">
                                        <div className="col">
                                            <SearchInput className="form-control" placeholder="Vyhledat service" onChange = {this.searchService} />
                                        </div>
                                        <div className="col col-sm-2">
                                            <button className="btn btn-danger btn-block ml-auto" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                    </div>

                                    {showSearchedServices &&

                                        <Mutation 
                                            mutation={ADD_ARTICLE_SERVICE}

                                            update = {(cache, response) => {

                                                let ar = new Article();
                                                ar.updateAfterAddArticleService(cache, response,articleID);

                                                let notify = new Notification();
                                                notify.setNotification(cache,'Úspěšně přidáno',true,false);

                                                this.setState({
                                                    showSearchedServices:false,
                                                    searchedServices:[]
                                                })

                                            }}
                                        >   
                                            {(addArticleService) => {  
                                                return(
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <div className="data-list">                                                                

                                                                    {searchedServices && searchedServices.map((item,index) => (

                                                                        <div key={index} className="data-list-item-content">
                                                                            <div>
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="text-right">
                                                                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addArticleService({variables:{serviceID: item.serviceID, articleID}})} >Přidat</button>
                                                                            </div>
                                                                        </div>

                                                                    ))}

                                                                    {searchedServices && searchedServices.length == 0 &&
                                                                        <NoItems text={"Nebyl nalezen žádný vyhovující service."} />
                                                                    } 

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Mutation>
                                    }
 
                                    <div className="data-list mt-2">

                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Název serviceu</div>
                                                <div className="text-right">Možnosti</div>
                                            </div>
                                        </div>

                                        <Mutation 
                                            mutation={UPDATE_ARTICLE_SERVICE_PRIORITY} 
                                            update={() => {
                                                refetch();
                                            }}
                                        >
                                        {
                                            (updateArticleServicePriority,{error}) => {

                                                if(error){
                                                    const helper = new Helper(); 
                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                }

                                                return (
                                                    <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateArticleServicePriority)}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {data.articleServices && data.articleServices.map((item,index) => {

                                                                        var checked = false;
                                                                        for(let i in selectedArticleServices){
                                                                            if(selectedArticleServices[i] == item.articleServiceID)checked = true;
                                                                        }

                                                                        return(
                                                                            <Draggable key={index} draggableId={item.articleServiceID} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                <div className="data-list-item" 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div>
                                                                                            
                                                                                            <img className="move-icon" src={move} />
                                                                                            {item.service.name}
                                                                                        </div>
                                                                                        <div className="text-right">

                                                                                            <input className="delete-checked" type="checkbox" name="deleteArticleService[]" checked={checked} onChange = {(e) => this.deleteItems(e,deleteArticleService,item.articleServiceID)} />
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}  
                                                                    
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                )
                                        }}
                                        </Mutation>

                                        {data.articleServices && data.articleServices.length == 0 &&
                                            <NoItems text={"Momentálně se zde nenachází žádné služby."} />
                                        }       

                                    </div>

                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odstranit vybrané služby ?'} callback={this.delItems} /> }
                                
                                </Fragment>
                                    
                                
                            )   
                            
                        }}
                        </Mutation>
                          
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default withApollo(ArticleServices);
