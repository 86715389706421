import React,{ Component } from 'react';

class Error extends Component {
	
  render() {
    return (
    	<div className="alert alert-danger">	
    		{this.props.text}
    	</div>
    );
  }
  
}


export default Error;
