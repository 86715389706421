/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_FORM = gql`
    mutation AddEditForm(
        $formID:ID,
        $thanksPageTypeID:ID,
        $thanksLinkID:ID,
        $langs:[FormLangsInput],
        $generateTranslations:GenerateFormTranslationsInput
    ){
        addEditForm(
            formID:$formID, 
            thanksPageTypeID:$thanksPageTypeID, 
            thanksLinkID:$thanksLinkID, 
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            formID
            name
            text
            sengridID
            sengridID2
            lang
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_FORM_PRIORITY = gql`
    mutation updateFormPriority($formID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateFormPriority(formID:$formID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_FORM = gql`
    query FormWithLangs($formID: ID!){
        formWithLangs(formID:$formID){
            formID
            thanksPageTypeID
            thanksLinkID
            langs{
                lang
                name
                text
                sengridID
                sengridID2
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allLinks(lang:"cz"){
            linkID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_FORMS = gql`
    query AllForms($lang: String!){
        allForms(lang: $lang){
            formID
            name
            text
            sengridID
            sengridID2
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_FORMS = gql`
    mutation deleteForms($formIDs:ID!) {
        deleteForms(formIDs:$formIDs)
    }
`;

/**
 * vyhledání služeb
 */
export const SEACRH_FORMS = gql`

    query SearchForms($text: String!,$lang:String){
        searchForms(text:$text,lang:$lang){
            name
            formID
        }

    }

`;

export const GENERATE_FORM_LANG = gql`
    mutation GenerateFormLang(
        $formID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateFormLang(
            formID:$formID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;