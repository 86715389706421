import UseGetAllExchangeRates from "../Library/UseGetAllExchangeRates";
import Loading from '../../../GlobalComponents/Loading';
import Error  from '../../../GlobalComponents/Error';
import { withApollo } from "react-apollo";

const ExchangeRates = ({client}) => {

    const {
        allCurrencies,
        loading,
        error,
        SaveExchangeRates,
        EditCurrency,
        CopyAndSaveExchangeRates
    } = UseGetAllExchangeRates(client);

    return(
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Měnové kurzy
                            <button 
                                className="btn btn-primary btn-thiner ml-auto" 
                                onClick={(e) => SaveExchangeRates()}
                                title = "Uloží změněné hodnoty kurzu v databázi"
                            >Uložit</button>
                            <button 
                                className="btn btn-primary btn-thiner ml-2" 
                                onClick={(e) => CopyAndSaveExchangeRates()}
                                title = "Zkopíruje kurzy ze světa do kolonek a uložíá je do databáze"
                            >Zkopírovat kurz ve světě a uložit</button>
                        </div>
                        <div className="card-body">
                             
                            <div className="data-list">            
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div className="static">Měna</div>
                                        <div className="static bigger text-center">Uložený kurz v databázi vůči CZK</div>
                                        <div className="">Aktuální kurz ve světě</div>
                                    </div>
                                </div>
                            </div>

                            {error ?
                                <Error text = {error} />
                            :
                                (loading ?
                                    <Loading />
                                :
                                    (allCurrencies && allCurrencies.map((item) => (
                                        <div 
                                            key={item.currencyID}
                                            className="data-list-item"
                                        >
                                            <div className="data-list-item-content">
                                                <div className="static">
                                                    {item.code}
                                                </div>
                                                <div className="static bigger">
                                                    <input 
                                                        className="form-control" 
                                                        name="name" 
                                                        type="number"
                                                        value = {item.exchangeRate} 
                                                        onChange={(e) => EditCurrency(item.currencyID,e.target.value)}
                                                    />
                                                </div>
                                                <div className="">
                                                    {item.worldExchangeRate}
                                                </div>
                                            </div>
                                        </div>
                                    )))
                                    
                                )
                            }

                        </div>
                    </div>
                </div>
            </div> 			
        </div>
    )
}

export default withApollo(ExchangeRates);