/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {REGENERATE_SERVICES_PRICE,GET_SERVICES,DELETE_SERVICES} from '../Queries/index.js';
import AddEditService from './AddEditService';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import Notification from '../../../Library/notification';
import ServiceLib from '../Library/service';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    showDeleteCategoryNotifi : false,
    showDoNotDelete:false,
    showAddCategory:false,
    selectedServiceID:0,
    serviceID:0,
    deleteCat:null,
    selectedItems:[],
    showAddCategory : false,
    showDeleteNotifi:false,
    showRegenerateNotify:false,
    regLoading:false,
    err:"",
    listVariables:{
        lang:"cz"
    }

}

class AllServices extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.RegeneratePrices = this.RegeneratePrices.bind(this);
    }


    delCategory = (action) => {
        if(action){
            let serviceIDs = this.state.selectedItems.join(",");
            this.state.deleteServices({
                variables:{
                    serviceIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,serviceID) => {
        this.setState({
            showAddCategory:type,
            selectedServiceID:serviceID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteServices funkce, která posílá data na server
     * @param {*} serviceID ID kategorie
     */
    
    checkItemToDelete = (e,deleteServices,serviceID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == serviceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [serviceID,...arr];
        }
        
        this.setState({
            deleteServices,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCategoryModal = (type) => {
        this.setState({
            showAddCategory:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,this.props.client);
        }
    }
    onDragEnd = (result,updateCategoryPriority) => {

        const IDs = result.draggableId.split("-");

        updateCategoryPriority({
            variables:{
                serviceID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    async RegeneratePrices(act)
    {
        if(act)
        {
            try
            {
                this.setState({regLoading:true});
                await this.props.client.mutate({
                    mutation:REGENERATE_SERVICES_PRICE,
                    onError:(err) => {
                        this.setState({err:err});
                    }
                })

                let notify = new Notification();
                notify.setNotification(null,'Úspěšně přegenerováno',true,false,this.props.client);
            }
            catch(err)
            {
                this.setState({err:err});
            }
        }
        
        this.setState({showRegenerateNotify:false,regLoading:false});
    }

    render(){
        
        const {
            selectedItems,
            listVariables,
            showRegenerateNotify,
            regLoading,
            err
        } = this.state;

        return(
            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam služeb
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => this.setState({showRegenerateNotify:true})}>Přegenerovat ceny všech</button>
                                <button className="btn btn-primary btn-thiner ml-2" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query 
                                    query={GET_SERVICES} 
                                    errorPolicy="all" 
                                    variables={listVariables}
                                >
                                    {
                                        ({data,loading,error,refetch }) => {

                                            if(error || err){
                                                const helper = new Helper(); 
                                                return (<Error text={helper.getApolloErrorText(error || err)} />);
                                            }
                                            if(loading || regLoading) return (<Loading />);
                                            
                                            return(
                                                <div>
                                                    <div className="data-list">
                                                        
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Název služby</div>
                                                                    <div className="text-center">Zobrazit po prodeji hl. produktu</div>
                                                                    <div className="text-center static">Cena</div>
                                                                    <div className="text-right static">Možnosti</div>
                                                                </div>
                                                            </div>

                                                            <Mutation
                                                                mutation = {DELETE_SERVICES}
                                                                update = {async (cache, response) => {
                                                                    
                                                                    let service = new ServiceLib(this);
                                                                    service.updateAfterDelete(cache, response);

                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteServices,response) => {
                                                                
                                                                        return(
                                                                            
                                                                            <div>
                                                                                {data.allServices && data.allServices.map((item,index) => {
                                                                                    
                                                                                    if(item.serviceID){
                                                                                        
                                                                                        var checked = false;
                                                                                        for(let i in selectedItems){
                                                                                            if(selectedItems[i] == item.serviceID)checked = true;
                                                                                        }

                                                                                        return (
                                                                                            
                                                                                            <div key={index} className="data-list-item">
                                                                                                <div className="data-list-item-content">
                                                                                                    <div className="cross" style={{paddingLeft:10}}>
                                                                                                        {item.name}
                                                                                                    </div>
                                                                                                    <div className="text-center">{item.sellAfterMainProduct != 0 ? "ANO" : "NE"}</div>
                                                                                                    <div className="text-center static">{item.price} Kč</div>
                                                                                                    <div className="text-right static">
                                                                                                        <img onClick={() => this.openCloseModal(true,item.serviceID)} className="edit-icon" src={edit} /> 
                                                                                                        <input className="delete-checked" type="checkbox" name="servicesToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteServices,item.serviceID)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }else{
                                                                                        return "";
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        
                                                    </div>
                                                    {data.allServices && data.allServices.length == 0 &&
                                                        <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                    }
                                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={this.delCategory} /> }
                                                    {this.state.showAddCategory &&
                                                        <AddEditService 
                                                            listVariables={listVariables} 
                                                            openCloseModal={this.openCloseModal} 
                                                            selectedServiceID={this.state.selectedServiceID} 
                                                            refetch = {refetch}
                                                        />
                                                    }
                                                    {showRegenerateNotify ?
                                                        <ModalNotification 
                                                            yesNo={true} 
                                                            callback={this.RegeneratePrices} 
                                                            text={"Opravdu chcete přegenerovat ceny všech služeb ?"}
                                                        />
                                                    :null}
                                                </div>
                                            );
                                        }
                                    }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 			
            </div>
        
        );

    }

}


export default withApollo(AllServices);