import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class VideoElement extends Component{

    constructor(props){
        super(props);
    }

    gup( name, url ) {
       
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]"+name+"=([^&#]*)";
        var regex = new RegExp( regexS );
        var results = regex.exec( url );
        return results == null ? null : results[1];
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">

                            {element.mp4FileUrl[lang] != "" && element.mp4FilePosterUrl[lang] != "" && 
                                <div className="form-group mr-1">
                                    <button className="blue" onClick={(e) => cm.setElementData("changeVideo",lastIndexes,"video",true)}>Vyměnit video</button>
                                </div>
                            }
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("video",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Typ:</label>
                                                    </div>
                                                    <select value={element.type[lang]} className="form-control" name="type" onChange={(e) => cm.setElementData(e,lastIndexes,"video",null,null,true)}>
                                                        <option value="youtube"> Yoututbe </option>
                                                        <option value="vimeo"> Vimeo </option>
                                                        <option value="mp4"> Mp4 </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {element.type[lang] != "mp4" ?                                           
                                                <div className="mb-1">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <label className="input-group-text">URL:</label>
                                                        </div>
                                                        <input type="text" value={element.url[lang]} className="form-control" name="url" onChange={(e) => cm.setElementData(e,lastIndexes,"video",null,null,true)}  />
                                                    </div>
                                                </div>
                                            :
                                                <>
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text">Zarovnání:</label>
                                                            </div>
                                                            <select value={element.mp4Align} className="form-control" name="mp4Align" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}>
                                                                <option value="start"> vlevo </option>
                                                                <option value="center"> centrovaně </option>
                                                                <option value="end"> vpravo </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text">Ovládací prvky:</label>
                                                            </div>
                                                            <select value={element.controls} className="form-control" name="controls" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}>
                                                                <option value="0"> Ne </option>
                                                                <option value="1"> Ano </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text">Popisek vespod:</label>
                                                            </div>
                                                            <select value={element.showFadeDesc} className="form-control" name="showFadeDesc" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}>
                                                                <option value="0"> Ne </option>
                                                                <option value="1"> Ano </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text">Zaoblení:</label>
                                                            </div>
                                                            <input type="number" value={element.borderRadius} className="form-control" name="borderRadius" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}  />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <label className="input-group-text">Používat rozměry:</label>
                                                            </div>
                                                            <select value={element.useDimensions} className="form-control" name="useDimensions" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}>
                                                                <option value="0"> Ne </option>
                                                                <option value="1"> Ano </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {element.useDimensions == 1 ?
                                                        <>
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Šířka:</label>
                                                                    </div>
                                                                    <input type="number" value={element.mp4Width} className="form-control" name="mp4Width" onChange={(e) => cm.setElementData(e,lastIndexes,"video")} />
                                                                </div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <label className="input-group-text">Výška:</label>
                                                                    </div>
                                                                    <input type="number" value={element.mp4Height} className="form-control" name="mp4Height" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}  />
                                                                </div>
                                                            </div>
                                                        </>
                                                    :null}
                                                </>
                                            }
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"video")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("video",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit video" title="Odstranit video" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"video",lastIndexes)}}>
                {element.type[lang] == "mp4" ?

                    (element.mp4FileUrl[lang] != "" && element.mp4FilePosterUrl[lang] != "" && !element.changeVideo ?
                        <div className={"d-flex justify-content-" + element.mp4Align}>
                            <div className="cm-video-content" style={(element.useDimensions == "1" ? {width:element.mp4Width+"px",height:element.mp4Height+"px"}:{})}>
                                <video playsInline loop muted autoPlay poster={element.mp4FilePosterUrl[lang]}  style={{borderRadius:element.borderRadius + "px"}}>
                                    <source src={element.mp4FileUrl[lang]} type="video/mp4"></source>
                                </video>
                                {element.showFadeDesc == "1" ?
                                    <div className="video-fade" >
                                        <p>STUDENT <a href="">ASTRÁLNÍ AKADEMIE</a></p>
                                    </div>
                                :null}
                            </div>
                        </div>
                    :
                        <div className="cm-video-choose-container">
                            <div className="custom-file mb-2">
                                <input type="file" className="custom-file-input" id="videoFile" onChange={(e) => cm.uploadVideoElement(e,lastIndexes,element.mp4FilePoster[lang],element.mp4FileUrl[lang],element.mp4FilePosterUrl[lang])}  />
                                <label className="custom-file-label" htmlFor="videoFile">{(element.mp4File[lang] ? element.mp4File[lang].name : "Vybrat video")}</label>
                            </div>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="videoPoster" onChange={(e) => cm.uploadVideoPoster(e,lastIndexes,element.mp4File[lang],element.mp4FilePosterUrl[lang],element.mp4FileUrl[lang])}  />
                                <label className="custom-file-label" htmlFor="videoPoster">{(element.mp4FilePoster[lang] ? element.mp4FilePoster[lang].name : "Vybrat náhled videa")}</label>
                            </div>
                            {element.changeVideo ?
                                <div className="txt-center"><button className="btn btn-primary mt-2" onClick={() => cm.setElementData("changeVideo",lastIndexes,"video",false)}>Zrušit výměnu videa</button></div>
                            :null}
                        </div>
                        
                    )

                :
                    (element.type[lang] == "youtube" ?
                        <iframe className={element.className} width="100%" height="300" src={"https://www.youtube.com/embed/" + this.gup( "v", element.url[lang] ) } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    :
                        <iframe className={element.className} src={"https://player.vimeo.com/video/" + (element.url[lang] ? element.url[lang].split("/").pop() : "")} width="100%" height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    )
                }
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default VideoElement;