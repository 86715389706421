import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class FormElement extends Component{

    constructor(props){
        super(props);
    }


    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;

        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("form",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">

                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Typ formuláře:</label>
                                                    </div>
                                                    <select value={element.formID} className="form-control" name="formID" onChange={(e) => cm.setElementData(e,lastIndexes,"form")}>
                                                        <option key={0} value={0}>--- vyberte formulář ---</option>
                                                        {this.props.allForms.map((item,index) => {
                                                            return(<option key={item.formID} value={item.formID}>{item.name}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                            </div>  
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Barva:</label>
                                                    </div>
                                                    <select value={element.color} className="form-control" name="color" onChange={(e) => cm.setElementData(e,lastIndexes,"form")}>
                                                        <option value=""> hnědá </option>
                                                        <option value="btn-green"> zelená </option>
                                                        <option value="btn-white"> bílá </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Velikost:</label>
                                                    </div>
                                                    <select value={element.size} className="form-control" name="size" onChange={(e) => cm.setElementData(e,lastIndexes,"form")}>
                                                        <option value=""> malá </option>
                                                        <option value="bigger"> střední </option>
                                                        <option value="biggest"> velká </option>
                                                    </select>
                                                </div>
                                            </div>                                     
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"form")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("form",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit formulář" title="Odstranit formulář" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"form",lastIndexes)}}>
                    <div className={"d-flex justify-content-center"}>
                        
                        <div className={"text-center " + element.className}>
                           
                            <input className="form-control" disabled name="form" placeholder="Email" />
                            <div>
                                <button className={"btn btn-primary mt-3 " + element.color + " " + element.size}><ContentEditable onClick={(e) => e.preventDefault()} tagName={"span"} html={(element.text ? element.text[lang] : "Odeslat")} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"form",null,null,true)}} /></button>
                            </div>
                            
                        </div>
                                                
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default FormElement;