import React,{ Component, Fragment } from 'react';
import Loading from '../../../GlobalComponents/Loading';
import {GET_LINKS} from '../Queries/index.js';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    loading:true,
    allLinks:[]

}

class LinkSelector extends Component{

    _isMounted = false;

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.getData();
    }
    componentWillUnmount() {
      this._isMounted = false;
    }

    getData = async () => {        
        this.props.client.query({ 
            query: GET_LINKS,
            errorPolicy:"all",
            variables:{lang:"cz"}, 
            fetchPolicy: 'network-only'
        }).then((response) => {

            try {
                if (this._isMounted) {
                    this.prepareLinks(response.data.allLinks,this.props.allArticleLinks);
                    this.setState({                
                        loading:false,
                        allLinks:response.data.allLinks
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }).catch((err) => {});
    }

    /**
     * Nastavi hodnoty odkazu selected na true nebo false podle toho jake odkazy byly k clanku prirazeny
     * 
     * @param {Array} allLinks - pole odkazů se stromovou strukturou
     * @param {Array} allArticleLinks - pole obsahujici linkID vsech pripojenych odkazu k clanku
     */
    prepareLinks = (allLinks,allArticleLinks) => {
        if(allLinks && allArticleLinks){
            for(const item of allLinks){
                if(allArticleLinks.indexOf(item.linkID) !== -1){
                    item.selected = true;
                }else{
                    item.selected = false;
                }
                
                if(item.subLink && item.subLink.length > 0){
                    this.prepareLinks(item.subLink,allArticleLinks);
                }
            }
        }
    }

    
    formHandle(e){
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;       

        let arr = [...this.state.allLinks];
        this.setSelected(arr,t.id,v,null);

        let allSelectedLinks = [];
        this.getSelectedLinks(arr,allSelectedLinks);

        this.setState({allLinks:arr});
              
        this.props.setSelectedLinks(allSelectedLinks);
    }
    
    /**
     * Rekurzivne zjistujeme ktere odkazy jsou zaklikle
     * 
     * @param {Array} arr - pole odkazů ve stromové struktúře
     * @param {Array} returnArray - pole obsahující veškeré zakliknuté odkazy
     */
    getSelectedLinks = (arr,returnArray) => {
        
        for(const item of arr){
            if(item.selected){
                returnArray.push(item.linkID);
            }
            if(item.subLink && item.subLink.length > 0){
                this.getSelectedLinks(item.subLink,returnArray);
            }
        }
    }

    /**
     * Rekurzivne zjistujeme na ktery odkaz bylo kliknuto
     * Pokud zaklikavame - jdeme postupne zpet v rekurzi a oznacujeme parenty taky jako oznacene
     * Pokud odklikvame - zavolame si funkci ktera rekurzivne projede vsechny linky zanorene nize a odklikne je taky
     * 
     * @param {Array} arr - pole odkazů
     * @param {Number} linkID - id odkazu na ktery jsme klikli
     * @param {Boolean} value - true/false jestli zaklikavame/odklikavame checkbox     
     */
    setSelected = (arr,linkID,value) => {
        for(const item of arr){
            
            if(item.linkID == linkID){
                item.selected = value;
                //pokud odklikavame checkbox
                if(!value){
                    if(item.subLink && item.subLink.length > 0){
                        this.unclickAllChildCheckboxes(item.subLink);
                    }
                //pokud zaklikavame checkbox
                }else{
                    return true;
                }
            }else{
                if(item.subLink && item.subLink.length > 0){                        
                    //pokud zaklikavame checkbox
                    if(value){
                        let recurseResult = this.setSelected(item.subLink,linkID,value);
                        if(recurseResult){
                            item.selected = true;
                            return true;
                        }                       
                    //pokud odklikavame checkbox
                    }else{
                        this.setSelected(item.subLink,linkID,value);
                    }
                }
            }
        }
        return false;
    }

    /**
     * Projíždí všechna pole rekurzivně níže a nastavuje jim checked na false
     * 
     * @param {Array} arr - pole odkazů
     */
    unclickAllChildCheckboxes = (arr) =>{
        
        for(const item of arr){
            item.selected = false;
            if(item.subLink && item.subLink.length > 0){
                this.unclickAllChildCheckboxes(item.subLink);
            }
        }
    }

    renderLinks = (links,isFirstLevel) => {
        let margin = "";
        if(!isFirstLevel){
            margin = "ml-3";
        }
        if(links && links.length > 0){
            return (
                <Fragment>
                    {links.map((item,index) => {
                        return(
                            <Fragment key={index}>
                                <div className={margin}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={item.selected} id={item.linkID} onChange={(e) => this.formHandle(e)} />
                                        <label className="form-check-label" htmlFor={item.linkID}>
                                            {item.name}
                                        </label>
                                    </div>
                                    {item.subLink && item.subLink.length > 0 &&
                                        this.renderLinks(item.subLink,false)
                                    }
                                </div>
                            </Fragment>
                        )
                    })}
                </Fragment>
            )
        }else{
            return "";
        }
    }


    render(){

        const {loading} = this.state;
        return(
            
            <div className="link-selector px-2">
                {!loading ?
                    <div>
                        {this.renderLinks(this.state.allLinks,true)}
                    </div>
                    :
                    <Loading displayText={false} />
                }
            </div>

        )

    }

}

export default withApollo(LinkSelector);