import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class FaqElement extends Component{

    constructor(props){
        super(props);
    }

    onPaste(e,type,index){
        e.preventDefault(); 
        const text = e.clipboardData.getData('text/plain'); 
        if(text != "")this.props.cm.setElementData(type,this.props.lastIndexes + "-" + index,"faq",text,null,true);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,lang} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">

                            <div className="form-group mr-1">
                                <button onClick={() => cm.addFaqQuestion(lastIndexes)}>Přidat otázku</button>
                            </div>
                            
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("faq",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">

                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Přeložit pouze toto:</label>
                                                    </div>
                                                    <select 
                                                        value={element.selectedForGenerateTranslation ? 1 : 0} 
                                                        className="form-control" 
                                                        name="selectedForGenerateTranslation" 
                                                        onChange={(e) => cm.setElementData(e,lastIndexes,"faq")}
                                                    >
                                                        <option value="0"> Ne </option>
                                                        <option value="1"> Ano </option>
                                                    </select>
                                                </div>
                                            </div>
                                                                                        
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"faq")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("faq",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit tlačítko" title="Odstranit faq" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"faq",lastIndexes)}}>
                    <div id="faq-element">

                        {element.questions.map((item,index) => (

                            <div key={index} className="faq-question">
                                
                                <img onClick={() => cm.removeFaqQuestion(lastIndexes + "-" + index)} className="remove-icon"  src={remove} alt="Odstranit otázku" title="Odstranit otázku" />
                                    
                                <div className="faq-question-title">
                                    <ContentEditable onClick={(e) => e.preventDefault()} tagName={"h4"} html={item.title[lang]} disabled={false} name="title" onPaste={(e) => this.onPaste(e,"title",index)} onChange={(evt) => {cm.setElementData(evt,lastIndexes + "-" + index,"faqQuestion",null,null,true)}} />
                                </div>
                                <div className="faq-question-text">
                                    <ContentEditable onClick={(e) => e.preventDefault()} tagName={"p"} html={item.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e,"text",index)} onChange={(evt) => {cm.setElementData(evt,lastIndexes + "-" + index,"faqQuestion",null,null,true)}} />
                                </div>

                            </div>

                        ))}
                                                                    
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container">
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default FaqElement;