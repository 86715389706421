import React,{ Component } from 'react';

class NoMatch extends Component {
		  	
	  render() {
		  
		this.props.history.push("/");
		
	    return (
	    	<div></div>
	    );
	  }
  
}


export default NoMatch;