const CampaignEmailTranslateSettings = ({generateTranslations,parent}) => {

    var items = [
        {name:"Předmět",param:"subject"},
        {name:"Preheader",param:"preheader"},
        {name:"Text",param:"text"}  
    ];

    const SetItem = (param) => {

        var arr = [...generateTranslations.onlyForParams];
        var checked = false;

        for(let i = 0; i < arr.length; i++)
        {
            if(arr[i] == param)
            {
                arr.splice(i,1);
                checked = true;
            }
        }

        if(!checked)
            arr.push(param);

        parent.campaignEmailLib.SetFormData("generateTranslations",{...generateTranslations,onlyForParams:arr})
    }

    return(
        <>
            <div className="translate-settings">

                <label className="input-label">Generovat překlady pouze pro: (pokud nevyberete žádné, tak se generují překlady pro všechny)</label>
                <div className="form-group">

                    {items.map((item,index) => {

                        var checked = false;
                        for(let val of generateTranslations.onlyForParams)
                        {
                            if(val == item.param)
                                checked = true;
                        }

                        return(
                            <div
                                key={index}
                                className="form-check form-check-inline"
                            >
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    name="generateTranslationsOnlyForParam" 
                                    id={"generateTranslationsOnlyForParam" + index} 
                                    checked={checked} 
                                    onChange={(e) => SetItem(item.param)} 
                                />
                                <label className="form-check-label" htmlFor={"generateTranslationsOnlyForParam" + index} >
                                    {item.name}
                                </label>
                            </div>
                        )

                    })}
                                    
                </div>
                
            </div>
            <div 
                className="translate-settings-overall"
                onClick = {() => parent.setState({showTranslateSettings:false})}
            ></div>
        </>
    )
}

export default CampaignEmailTranslateSettings;