import React,{ Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


const SeparatorElement = (props) => {

    const {element,showHelpers,cm,lastIndexes,lang} = props;
    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className="cm-headline">

            {showHelpers && element.showMenu &&
                <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                    <div className="form-inline">
                        
                        <div className="form-group mr-1">
                            <div className="item-container" onClick={() => cm.showSettings("separator",lastIndexes)}>
                                <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                            </div>
                            {element.showSettings &&
                                <Fragment>
                                    <div className="settings">
                                    
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Třída:</label>
                                                </div>
                                                <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"separator")}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cm-closing-overlay" onClick={() => cm.showSettings("separator",lastIndexes)}></div>
                                </Fragment>
                            }
                        </div>
                        {parentIndexes != "" &&
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                <img className="remove-icon"  src={remove} alt="Odstranit oddělovač" title="Odstranit oddělovač" />
                            </div>
                        </div>
                    </div>  
                </div>
            }

            <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"separator",lastIndexes)}}>
                <div className={"d-flex"}>
                    
                    
                    <div className={"separator-container"}>

                        
                        <div className={"colored-line " + element.className}>
                            <div className="black-line"></div>
                        </div>
                        
                    </div>
                                            
                </div>
            </div>
            {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
            {showHelpers && 
                <div className="cm-dragable-item" {...props.handle}>
                    <div className="item-container">
                        <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                    </div>
                </div>
            }

        </div>
    );

}

export default SeparatorElement;