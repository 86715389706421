import {GET_CAMPAIGN_CONTACTS} from '../Queries/campaignContact.js';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper.js';

export const UpdateAfterDelete = (cache, response,listVariables) => {
 
    var resp = response.data.unsubscribeCampaigns.campaignContactIDs.split(",");

    const { allCampaignContacts } = cache.readQuery({ query: GET_CAMPAIGN_CONTACTS,variables:listVariables});
    var arr = [...allCampaignContacts];
    
    resp.forEach((it,ind) => {
        arr.forEach((item,index) => {
            if(item.campaignContactID == it){
                arr[index].isFinished      = response.data.unsubscribeCampaigns.isFinished;
                arr[index].dateUnsubscribe = response.data.unsubscribeCampaigns.dateUnsubscribe;
                arr[index].unsubscribeByAdminID = response.data.unsubscribeCampaigns.unsubscribeByAdminID;
            }
        });
    }) 
   
    cache.writeQuery({ 
        query:GET_CAMPAIGN_CONTACTS,
        variables:listVariables,
        data:{
            allCampaignContacts: arr
        } 
    });

}
export const UpdateAfterAdd = (cache, response,listVariables) => {
 
    const { allCampaignContacts } = cache.readQuery({ query: GET_CAMPAIGN_CONTACTS,variables:listVariables});
    
    cache.writeQuery({ 
        query:GET_CAMPAIGN_CONTACTS,
        variables:listVariables,
        data:{
            allCampaignContacts: [...response.data.addCampaignContacts.contacts,...allCampaignContacts]
        } 
    });

}

export const AddContacts = (AddCampaignContacts,formData,campaignID,client) => {

    let notify = new Notification();  

    if(formData.contacts != ""){

        var helper = new Helper();
        var arr = formData.contacts.split(" ");

        var check = true;
        for(var val of arr){

            if(!helper.validateEmail(val)) 
                check = false;
            
        }

        if(check){

            AddCampaignContacts({
                variables:{
                    campaignID,
                    lang:formData.lang,
                    emails:formData.contacts
                }
            });

        }else{
            notify.setNotification(null,"Omrkni znovu emaily, některý má blbý tvar.",false,true,client);
        }

    }else{
        notify.setNotification(null,"Bez emailu kontakt fakt nevložíš :)",false,true,client);
    }
}

export default UpdateAfterDelete;