/**
 * soubor se seznamem veškerých graphql dotazů pro odkazy
 */
import gql from 'graphql-tag';

/**
 * vložení odkazu
 */
export const ADD_EDIT_LINK = gql`
    mutation AddEditLink(
        $linkID:ID,
        $parentID: String!, 
        $themeID: ID,
        $active:Int, 
        $inHeader:Int, 
        $inFooter:Int, 
        $isBlog:Int,
        $hideMenu:Int,
        $thanksLinkID:ID,
        $metaImage:Upload,
        $langs:[LinkLangsInput],
        $memberships:[InputLinkMemberships],
        $membershipPercentageDiscountDateFrom:String,
        $membershipPercentageDiscountDateTo:String
        $upSellBombItems:[UpSellBombItemInput],
        $generateTranslations:GenerateLinkTranslationsInput
    ){
        addEditLink(
            linkID:$linkID,
            parentID: $parentID, 
            themeID:$themeID,
            active:$active, 
            inHeader:$inHeader,
            inFooter:$inFooter,
            isBlog:$isBlog,
            metaImage:$metaImage,
            langs:$langs,
            hideMenu:$hideMenu,
            thanksLinkID:$thanksLinkID,
            memberships:$memberships,
            membershipPercentageDiscountDateFrom:$membershipPercentageDiscountDateFrom,
            membershipPercentageDiscountDateTo:$membershipPercentageDiscountDateTo,
            upSellBombItems:$upSellBombItems,
            generateTranslations:$generateTranslations
        ){
            linkID    
        }
    }
`;

/**
 *  Úprava priority odkazu, když se přesouvají položky
 */
export const UPDATE_LINK_PRIORITY = gql`
    mutation updateLinkPriority($parentID:String!,$linkID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateLinkPriority(parentID:$parentID,linkID:$linkID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;
export const COPY_LINK = gql`
    mutation copyLink($linkID:ID) {
        copyLink(linkID:$linkID)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID!){
        linkWithLangs(linkID:$linkID){
            linkID
            active
            parentID
            themeID
            inHeader
            inFooter 
            isBlog
            hideMenu
            metaImage
            thanksLinkID
            membershipPercentageDiscountDateFrom
            membershipPercentageDiscountDateTo
            memberships{
                membershipDurationID
                percentageDiscount
            }
            upSellBombItems{
                upSellBombTypeID
                valueID
            }
            langs{
                lang
                name
                ownUrl
                description
                membershipPopUpTextUp
			    membershipPopUpTextDown
                membershipAfterBuyEmailText
                niceName
                metaTitle
                metaKeywords
                metaDescription
                upSellBombTitle
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allMemberships(lang:"cz"){
            membershipDurationID
            name
        }
        allServices(lang:"cz"){
            serviceID
            name
        }
        allLinks(lang:"cz"){
            linkID
            name
            subLink{
                linkID
                name
                subLink{
                    linkID
                    name
                    subLink{
                        linkID
                        name
                        subLink{
                            linkID
                            name
                            subLink{
                                linkID
                                name
                                subLink{
                                    linkID
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_LINKS = gql`
    query AllLinks($lang: String){
        allLinks(lang:$lang){
            linkID
            linkLangID
            name
            active
            parentID
            inHeader
            inFooter 
            hideMenu
            subLink{
                linkID
                linkLangID
                name
                active
                parentID
                inHeader
                inFooter 
                hideMenu
                subLink{
                    linkID
                    linkLangID
                    name
                    active
                    parentID
                    inHeader
                    inFooter 
                    hideMenu
                    subLink{
                        linkID
                        linkLangID
                        name
                        active
                        parentID
                        inHeader
                        inFooter 
                        hideMenu
                        subLink{
                            linkID
                            linkLangID
                            name
                            active
                            parentID
                            inHeader
                            inFooter 
                            hideMenu
                            subLink{
                                linkID
                                linkLangID
                                name
                                active
                                parentID
                                inHeader
                                inFooter 
                                hideMenu
                                subLink{
                                    linkID
                                    linkLangID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter 
                                    hideMenu
                                }
                            }
                        }
                    }
                }
            }
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_LINKS = gql`
    mutation deleteLink($linkID:ID!,$parentID:String) {
        deleteLink(linkID:$linkID)
        reindexPriority(parentID:$parentID)
    }
`;

export  const GENERATE_LINK_LANG = gql`
    mutation GenerateLinkLang(
        $linkID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateLinkLang(
            linkID:$linkID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;
