import React,{ Component, Fragment } from 'react';
import {Query,Mutation} from 'react-apollo';
import {GET_ARTICLE_FORMS,DELETE_ARTICLE_FORM,ADD_ARTICLE_FORM,UPDATE_ARTICLE_FORM_PRIORITY} from '../Queries/article.js';
import {SEACRH_FORMS} from '../../Form/Queries/index';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import SearchInput from '../../../GlobalComponents/SearchInput';
import { withApollo } from 'react-apollo';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Helper from '../../../Library/helper';
import Article from '../Library/article.js';

const INITIAL_STATE = {
    showDeleteNotifi:false,
    deleteArticleForm:"",
    articleFormID:0,
    selectedArticleForms:[],
    showSearchedForms:false,
    searchedForms:[]
}

class ArticleForms extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

    }

    componentDidMount(){
    }

    /**
     * smazání doporučených článků
     * @param {*} deleteArticleForm funkce, která posílá data na server
     * @param {*} articleFormID ID doporučeného článku
     */
    
    deleteItems = (e,deleteArticleForm,articleFormID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedArticleForms];

        if(!checked){
            for(let i in this.state.selectedArticleForms){
                if(this.state.selectedArticleForms[i] == articleFormID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleFormID,...arr];
        }

        
        this.setState({
            deleteArticleForm,
            selectedArticleForms:arr
        });
  
    }

    delItems = (action) => {

        if(action){

            let articleFormIDs = this.state.selectedArticleForms.join(",");

            this.state.deleteArticleForm({
                variables:{
                    articleFormIDs: articleFormIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi = () => {

        if(this.state.selectedArticleForms.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste službu.',false,true,this.props.client);
        }
    }

    searchForm = async (value) =>{        

        var data = await this.props.client.query({ 
            query: SEACRH_FORMS,
            variables:{
                text:value,
                lang:"cz"
            }, 
            fetchPolicy: 'network-only'
        });

        this.setState({
            showSearchedForms:true,
            searchedForms:data.data.searchForms
        })
    
    }
    
    onDragEnd = (result,updateArticleFormPriority) => {

        const IDs = result.draggableId.split("-");

        updateArticleFormPriority({
            variables:{
                articleFormID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        const {articleID} = this.props;
        const {selectedArticleForms,showSearchedForms,searchedForms} = this.state;

        return(
            <div>
                
                <Query query={GET_ARTICLE_FORMS} variables={{articleID,lang:"cz"}} fetchPolicy = 'network-only' >
                {({data,loading,error,refetch}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_ARTICLE_FORM}
                            
                            update = {(cache, response) => {

                                let ar = new Article();
                                ar.updateAfterDeleteArticleForm(cache, response,articleID);

                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);

                            }}
                        >   
                        {(deleteArticleForm) => {    

                            return(

                                <Fragment>

                                    <div className="row form-group mb-0">
                                        <div className="col">
                                            <SearchInput className="form-control" placeholder="Vyhledat formulář" onChange = {this.searchForm} />
                                        </div>
                                        <div className="col col-sm-2">
                                            <button className="btn btn-danger btn-block ml-auto" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                    </div>

                                    {showSearchedForms &&

                                        <Mutation 
                                            mutation={ADD_ARTICLE_FORM}

                                            update = {(cache, response) => {

                                                let ar = new Article();
                                                ar.updateAfterAddArticleForm(cache, response,articleID);

                                                let notify = new Notification();
                                                notify.setNotification(cache,'Úspěšně přidáno',true,false);

                                                this.setState({
                                                    showSearchedForms:false,
                                                    searchedForms:[]
                                                })

                                            }}
                                        >   
                                            {(addArticleForm) => {  
                                                return(
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <div className="data-list">                                                                

                                                                    {searchedForms && searchedForms.map((item,index) => (

                                                                        <div key={index} className="data-list-item-content">
                                                                            <div>
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="text-right">
                                                                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addArticleForm({variables:{formID: item.formID, articleID}})} >Přidat</button>
                                                                            </div>
                                                                        </div>

                                                                    ))}

                                                                    {searchedForms && searchedForms.length == 0 &&
                                                                        <NoItems text={"Nebyl nalezen žádný vyhovující formulář."} />
                                                                    } 

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Mutation>
                                    }
 
                                    <div className="data-list mt-2">

                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Název formuláře</div>
                                                <div className="text-right">Možnosti</div>
                                            </div>
                                        </div>

                                        <Mutation 
                                            mutation={UPDATE_ARTICLE_FORM_PRIORITY} 
                                            update={() => {
                                                refetch();
                                            }}
                                        >
                                        {
                                            (updateArticleFormPriority,{error}) => {

                                                if(error){
                                                    const helper = new Helper(); 
                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                }

                                                return (
                                                    <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateArticleFormPriority)}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {data.articleForms && data.articleForms.map((item,index) => {

                                                                        var checked = false;
                                                                        for(let i in selectedArticleForms){
                                                                            if(selectedArticleForms[i] == item.articleFormID)checked = true;
                                                                        }

                                                                        return(
                                                                            <Draggable key={index} draggableId={item.articleFormID} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                <div className="data-list-item" 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div>
                                                                                            
                                                                                            <img className="move-icon" src={move} />
                                                                                            {item.form.name}
                                                                                        </div>
                                                                                        <div className="text-right">

                                                                                            <input className="delete-checked" type="checkbox" name="deleteArticleForm[]" checked={checked} onChange = {(e) => this.deleteItems(e,deleteArticleForm,item.articleFormID)} />
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}  
                                                                    
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                )
                                        }}
                                        </Mutation>

                                        {data.articleForms && data.articleForms.length == 0 &&
                                            <NoItems text={"Momentálně se zde nenachází žádné formuláře."} />
                                        }       

                                    </div>

                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odstranit vybrané formuláře ?'} callback={this.delItems} /> }
                                
                                </Fragment>
                                    
                                
                            )   
                            
                        }}
                        </Mutation>
                          
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default withApollo(ArticleForms);
