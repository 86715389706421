import React,{ Component, Fragment } from 'react';
import {Query,Mutation} from 'react-apollo';
import {GET_ARTICLE_MARKETINGS,DELETE_ARTICLE_MARKETING,ADD_ARTICLE_MARKETING,UPDATE_ARTICLE_MARKETING_PRIORITY} from '../Queries/article.js';
import {SEACRH_MARKETINGS} from '../../Marketing/Queries/marketing';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import SearchInput from '../../../GlobalComponents/SearchInput';
import {ARTICLE_IMAGE_URL,MARKETING_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import { withApollo } from 'react-apollo';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Helper from '../../../Library/helper';
import Article from '../Library/article.js';

const INITIAL_STATE = {
    showDeleteNotifi:false,
    deleteArticleMarketing:"",
    articleMarketingID:0,
    selectedArticleMarketings:[],
    showSearchedMarketings:false,
    searchedMarketings:[]
}

class ArticleMarketings extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

    }

    componentDidMount(){
    }

    /**
     * smazání doporučených článků
     * @param {*} deleteArticleMarketing funkce, která posílá data na server
     * @param {*} articleMarketingID ID doporučeného článku
     */
    
    deleteItems = (e,deleteArticleMarketing,articleMarketingID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedArticleMarketings];

        if(!checked){
            for(let i in this.state.selectedArticleMarketings){
                if(this.state.selectedArticleMarketings[i] == articleMarketingID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [articleMarketingID,...arr];
        }

        
        this.setState({
            deleteArticleMarketing,
            selectedArticleMarketings:arr
        });
  
    }

    delItems = (action) => {

        if(action){

            let articleMarketingIDs = this.state.selectedArticleMarketings.join(",");

            this.state.deleteArticleMarketing({
                variables:{
                    articleMarketingIDs: articleMarketingIDs
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi = () => {

        if(this.state.selectedArticleMarketings.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste marketing.',false,true,this.props.client);
        }
    }

    searchMarketing = async (value) =>{        

        var data = await this.props.client.query({ 
            query: SEACRH_MARKETINGS,
            variables:{
                text:value,
                lang:"cz"
            }, 
            fetchPolicy: 'network-only'
        });

        this.setState({
            showSearchedMarketings:true,
            searchedMarketings:data.data.searchMarketings
        })
    
    }
    
    onDragEnd = (result,updateArticleMarketingPriority) => {

        const IDs = result.draggableId.split("-");

        updateArticleMarketingPriority({
            variables:{
                articleMarketingID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        const {articleID} = this.props;
        const {selectedArticleMarketings,showSearchedMarketings,searchedMarketings} = this.state;

        return(
            <div>
                
                <Query query={GET_ARTICLE_MARKETINGS} variables={{articleID,lang:"cz"}} fetchPolicy = 'network-only' >
                {({data,loading,error,refetch}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_ARTICLE_MARKETING}
                            
                            update = {(cache, response) => {

                                let ar = new Article();
                                ar.updateAfterDeleteArticleMarketing(cache, response,articleID);

                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);

                            }}
                        >   
                        {(deleteArticleMarketing) => {    

                            return(

                                <Fragment>

                                    <div className="row form-group mb-0">
                                        <div className="col">
                                            <SearchInput className="form-control" placeholder="Vyhledat marketing" onChange = {this.searchMarketing} />
                                        </div>
                                        <div className="col col-sm-2">
                                            <button className="btn btn-danger btn-block ml-auto" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                    </div>

                                    {showSearchedMarketings &&

                                        <Mutation 
                                            mutation={ADD_ARTICLE_MARKETING}

                                            update = {(cache, response) => {

                                                let ar = new Article();
                                                ar.updateAfterAddArticleMarketing(cache, response,articleID);

                                                let notify = new Notification();
                                                notify.setNotification(cache,'Úspěšně přidáno',true,false);

                                                this.setState({
                                                    showSearchedMarketings:false,
                                                    searchedMarketings:[]
                                                })

                                            }}
                                        >   
                                            {(addArticleMarketing) => {  
                                                return(
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <div className="data-list">                                                                

                                                                    {searchedMarketings && searchedMarketings.map((item,index) => (

                                                                        <div key={index} className="data-list-item-content">
                                                                            <div>
                                                                                {item.photo && 
                                                                                    <img className="article-list-img mr-2" alt={item.title} src={SERVER_URL + "/" + MARKETING_IMAGE_URL + "/mala_" + item.photo} />
                                                                                }
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-right">
                                                                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addArticleMarketing({variables:{marketingID: item.marketingID, articleID}})} >Přidat</button>
                                                                            </div>
                                                                        </div>

                                                                    ))}

                                                                    {searchedMarketings && searchedMarketings.length == 0 &&
                                                                        <NoItems text={"Nebyl nalezen žádný vyhovující marketing."} />
                                                                    } 

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Mutation>
                                    }
 
                                    <div className="data-list mt-2">

                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Název marketingu</div>
                                                <div className="text-right">Možnosti</div>
                                            </div>
                                        </div>

                                        <Mutation 
                                            mutation={UPDATE_ARTICLE_MARKETING_PRIORITY} 
                                            update={() => {
                                                refetch();
                                            }}
                                        >
                                        {
                                            (updateArticleMarketingPriority,{error}) => {

                                                if(error){
                                                    const helper = new Helper(); 
                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                }

                                                return (
                                                    <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateArticleMarketingPriority)}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {data.articleMarketings && data.articleMarketings.map((item,index) => {

                                                                        var checked = false;
                                                                        for(let i in selectedArticleMarketings){
                                                                            if(selectedArticleMarketings[i] == item.articleMarketingID)checked = true;
                                                                        }

                                                                        return(
                                                                            <Draggable key={index} draggableId={item.articleMarketingID} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                <div className="data-list-item" 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div>
                                                                                            
                                                                                            <img className="move-icon" src={move} />
                                                                                            {item.marketing.photo && 
                                                                                                <img className="article-list-img mr-2" alt={item.marketing.title} src={SERVER_URL + "/" + MARKETING_IMAGE_URL + "/mala_" + item.marketing.photo} />
                                                                                            }
                                                                                            {item.marketing.title}
                                                                                        </div>
                                                                                        <div className="text-right">

                                                                                            <input className="delete-checked" type="checkbox" name="deleteArticleMarketing[]" checked={checked} onChange = {(e) => this.deleteItems(e,deleteArticleMarketing,item.articleMarketingID)} />
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}  
                                                                    
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                )
                                        }}
                                        </Mutation>

                                        {data.articleMarketings && data.articleMarketings.length == 0 &&
                                            <NoItems text={"Momentálně se zde nenachází žádné marketingy."} />
                                        }       

                                    </div>

                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odstranit vybrané marketingy ?'} callback={this.delItems} /> }
                                
                                </Fragment>
                                    
                                
                            )   
                            
                        }}
                        </Mutation>
                          
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default withApollo(ArticleMarketings);
