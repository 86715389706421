/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

export const GET_CURRENCIES = gql`
    query AllCurrencies{
        AllCurrencies{
            currencyID
            title
            code
            exchangeRate
            worldExchangeRate
        } 
    }
`;

export const SAVE_EXCHANGE_RATES = gql`
    mutation SaveExchangeRates($data:[EditExchangeRatesInput]){
        SaveExchangeRates(data:$data)
    }
`;