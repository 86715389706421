import { useQuery,withApollo } from "react-apollo";
import { GET_ORDERS } from "../Queries/order";
import Loading from "../../../GlobalComponents/Loading";
import Helper from "../../../Library/helper";
import Error from "../../../GlobalComponents/Error";
import edit from '../../../Public/Images/edit.svg';
import notes from '../../../Public/Images/notes.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import ManufacturedStatus from "./ManufacturedStatus";
import AddEditOrder from "./AddEditOrder";
import moment from "moment";
import { useState } from "react";
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Notification from "../../../Library/notification";

const Order = ({client,adminUserContext}) => {

    const helper = new Helper()

    const [orderID, SetOrderID] = useState(0);
    const [listVariables, SetListVariables] = useState({
        status:"COMPLETED",
        offset:0,
        limit:50,
        dateFrom: moment().subtract(2,'months').startOf("day").toDate(),
        dateTo:moment().endOf("day").toDate(),
    });

    var queryVariables = {
        status:listVariables.status,
        offset:listVariables.offset,
        limit:listVariables.limit,
        dateFrom: moment(listVariables.dateFrom).format("YYYY-MM-DD 00:00:00"),
        dateTo:moment(listVariables.dateTo).format("YYYY-MM-DD 23:59:59"),
    }
    
    const {data,loading,error,fetchMore} = useQuery(GET_ORDERS,{
        variables:queryVariables,
        fetchPolicy:"network-only"
    })

    const CopyAddress = (customer) => {

        var address = customer.name + " " + customer.surname + ", " + customer.street + ", " + customer.zip + ", " + customer.city + ", " + customer.country.name
        navigator.clipboard.writeText(address);

        let notify = new Notification();
        notify.setNotification(null,'Zkopírováno',true,false,client);

    }
    
    var err = "";
    if(error)
        err = helper.getApolloErrorText(error);

    return(
        <div id="settings" className="whole-container" >
            <div className="row" >
                <div className="col-sm one">

                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            Seznam objednávek k výrobě
                            <div className="ml-auto d-flex align-items-center">
                                <div className="mr-2"><strong>Od: </strong></div>
                                <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={listVariables.dateFrom} onChange={date => SetListVariables({...listVariables,dateFrom:date})} />
                                <div className="mr-2 ml-2"><strong> Do: </strong></div>
                                <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={listVariables.dateTo} onChange={date => SetListVariables({...listVariables,dateTo:date})} />
                                <div className="mr-2 ml-2"><strong> Počet: </strong></div>
                                <div><strong> {data && data.allOrdersCount} </strong></div>
                            </div>
                        </div>
                        <div className="card-body">
            
                            {err ?
                                <Error text={err} />
                            :
                                (loading ?
                                    <Loading />
                                :
                                    <div>
                                        <div className="data-list">
                                            
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div className="static">Jméno</div>
                                                    <div className="static">Příjmení</div>
                                                    <div className="">Adresa</div>
                                                    <div className="static">Stát</div>
                                                    {adminUserContext.adminRoleID == 1 ?
                                                        <>
                                                            <div className="">Email</div>
                                                            <div className="text-center static">Cena</div>
                                                        </>
                                                    :null}
                                                    <div className="text-center static">Datum vytvoření</div>
                                                    <div className="text-center static">Status výroby</div>
                                                    <div className="text-right static small">Možnosti</div>
                                                </div>
                                            </div>
                                            
                                            {data.allOrders && data.allOrders.map((item,index) => {
                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div className="static">{item.customer && item.customer.name}</div>
                                                        <div className="static">{item.customer && item.customer.surname}</div>
                                                        <div className="">{item.customer && (item.customer.street + ", " + item.customer.zip + ", " + item.customer.city)}</div>
                                                        <div className="static">{item.customer && item.customer.country.name}</div>
                                                        {adminUserContext.adminRoleID == 1 ?
                                                            <>
                                                                <div className="">{item.customer && item.customer.email}</div>
                                                                <div className="text-center static">{
                                                                    item.currencyCode ? 
                                                                        helper.GetPriceString(item.amount / 100,item.lang,item.currencyCode) 
                                                                    : 
                                                                        (item.customer ?
                                                                            helper.GetPriceString(item.amount / 100,item.customer.domain.lang,item.customer.domain.currencyCode)
                                                                        :null)
                                                                }</div>
                                                            </>
                                                        :null}
                                                        <div className="text-center static">{moment(item.dateAdd).format("DD.MM.YYYY HH:mm:ss")}</div>
                                                        <div className="text-center static">
                                                            <ManufacturedStatus status={item.manufacturedStatus} dateChanged={item.dateManufacturedStatusChanged} />
                                                        </div>
                                                        <div className="text-right static small">

                                                            <img title="Kopírovat adresu" onClick={() => CopyAddress(item.customer)} className="content-icon mr-2" src={notes} /> 
                                                            <img title="Upravit objednávku" onClick={() => SetOrderID(item.customerPaymentID)} className="edit-icon" src={edit} /> 
                                                            
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 

                                                            
                                        </div>
                                        
                                        {data.allOrders && data.allOrders.length == 0 ?
                                            <NoItems text={"Momentálně se zde nenachází žádné objednávky."} />
                                        :
                                            <div className="text-center p-5">
                                                <button className="btn btn-primary" onClick={() => fetchMore({
                                                    variables: {
                                                        offset: data.allOrders.length
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                        if (!fetchMoreResult) return prev;
                                                        return Object.assign({}, prev, {
                                                            allOrders: [...prev.allOrders, ...fetchMoreResult.allOrders]
                                                        });
                                                    }
                                                })}> Načíst další objednávky </button>
                                            </div> 

                                        }
                                        
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>
            {orderID != 0 ?
                <AddEditOrder orderID={orderID} SetOrderID={SetOrderID} listVariables={queryVariables} />
            :null}
        </div>
    )

}

export default withApollo(Order);