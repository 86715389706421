import moment from "moment";

const ManufacturedStatus = ({status,dateChanged}) => {

    var text = "";
    var color = "";

    switch(status)
    {
        case "PROCESSING": text = "ZPRACOVÁVÁ SE"; color = "warning"; break;
        case "MANUFACTURED": text = "VYROBENO"; color = "info"; break;
        case "SEND": text = "ODESLÁNO"; color = "success"; break;
    }

    return(
        <div className={"badge badge-" + color}>
            {text} 
            {dateChanged != "0000-00-00 00:00:00" ?
                <div className="date">{moment(dateChanged).format("DD.MM.YYYY HH:mm:ss")}</div>
            :null}
        </div>
    )

}

export default ManufacturedStatus;