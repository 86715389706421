import {useMutation, useQuery} from 'react-apollo';
import { GET_CURRENCIES, SAVE_EXCHANGE_RATES } from '../Queries';
import Helper from '../../../Library/helper.js';
import { useState } from 'react';
import Notification from '../../../Library/notification.js';

const UseGetAllExchangeRates = (client) => {

    const helper = new Helper();
    const [allCurrencies, SetAllCurrencies] = useState([]);

    const {data,loading,error} = useQuery(GET_CURRENCIES,{
        fetchPolicy:"network-only",
        onCompleted:(data) => {
            SetAllCurrencies(data.AllCurrencies);
        }
    });

    const [SaveExchangeRatesMutation,{}] = useMutation(SAVE_EXCHANGE_RATES,{
        onCompleted:(data) => {

            let notify = new Notification();
            notify.setNotification(null,'Úspěšně uloženo',true,false,client);

        },
        onError:(err) => {
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,client);
        }
    });

    const CopyAndSaveExchangeRates = () => {

        var arr = [...allCurrencies];
        for(let i = 0; i < arr.length; i++)
        {
            arr[i] = {...arr[i],exchangeRate:arr[i].worldExchangeRate}
        }

        SetAllCurrencies(arr);
        SaveExchangeRates();

    }

    const SaveExchangeRates = () => {

        SetAllCurrencies((prevAllCurrencies) => {

            console.log("huu");

            var check = true;
            var dataToSend = [];
            for(let val of prevAllCurrencies)
            {
                if(!val.exchangeRate)
                    check = false;

                dataToSend.push({
                    currencyID:val.currencyID,
                    exchangeRate: val.exchangeRate
                })
            }

            if(check)
                SaveExchangeRatesMutation({
                    variables:{
                        data:dataToSend
                    }
                })
            else
            {
                let notify = new Notification();
                notify.setNotification(null,'Některý kurz nebyl vyplněn',false,true,client);
            }

            return prevAllCurrencies;

        });
     
    }

    const EditCurrency = (currencyID,value) => {
        
        var arr = [...allCurrencies];
        for(let i = 0; i < arr.length; i++)
        {
            if(arr[i].currencyID == currencyID)
                arr[i] = {...arr[i],exchangeRate:parseFloat(value)}
        }

        SetAllCurrencies(arr);

    }

    return {
        loading,
        error: error && helper.getApolloErrorText(error),
        allCurrencies: allCurrencies,
        EditCurrency,
        SaveExchangeRates,
        CopyAndSaveExchangeRates
    }

}

export default UseGetAllExchangeRates;