const ProgressBar = ({progressValue,totalValue}) => {

    const percentage = Math.round(progressValue / totalValue * 100);

    return(
        <div className="progress-bar">
            <div className="progress" style={{width:percentage + "%"}}></div>
        </div>
    )
}

export default ProgressBar;