/**
 * soubor se seznamem veškerých graphql dotazů pro marketingy
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_MARKETING = gql`
    mutation AddEditMarketing($marketingID:ID!,$photo:Upload,$photoPortrait:Upload,$showInBlog:Int,$showInAllArticles:Int,$langs:[MarketingLangsInput]){
        addEditMarketing(marketingID:$marketingID,photo:$photo,photoPortrait:$photoPortrait,showInBlog:$showInBlog,showInAllArticles:$showInAllArticles,langs:$langs){
            marketingID
            title
            text
            photo
            lang
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_MARKETING = gql`
    query MarketingWithLangs($marketingID: ID!){
        marketingWithLangs(marketingID:$marketingID){
            marketingID
            photo
            photoPortrait
            showInBlog
            showInAllArticles
            langs{
                lang
                title
                text
                buttonText
                buttonUrl
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých marketingů
 */
export const GET_MARKETINGS = gql`
    query AllMarketings($lang: String!){
        allMarketings(lang: $lang){
            marketingID
            title
            text
            photo
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_MARKETINGS = gql`
    mutation deleteMarketings($marketingIDs:ID!) {
        deleteMarketings(marketingIDs:$marketingIDs)
    }
`;


/**
 * vyhledání marketingů
 */
export const SEACRH_MARKETINGS = gql`

    query SearchMarketings($text: String!,$lang:String){
        searchMarketings(text:$text,lang:$lang){
            title
            marketingID
            photo 
        }

    }

`;
